<script setup lang="ts">
const { t } = useT();
const isGuest = useIsGuest();
const { open } = useAppModals();
const { bingoData, isCanPlay } = useBingoData({ immediate: false });
const loginGuard = useLoginGuard();
const { refresh: refreshAppInitData } = useAppInitData();

const buttonName = computed(() => {
	if (isGuest.value) {
		return t("bingo.promo.card.button.name1");
	}
	if (isCanPlay.value) {
		return t("bingo.promo.card.button.name2");
	}
	return t("bingo.promo.card.button.name3");
});

const refreshData = () => {
	setTimeout(() => {
		refreshAppInitData();
	}, 1000);
};

const { durationLeft: promotionTime, reset } = useCountdown({
	timestamp: (bingoData.value?.bingoEndDate as string) || "",
	formatToken: "DD[D ] HH[H ]mm[M ]",
	isOptimized: true,
	onCountdownStop: refreshData
});

const unwatchBingoEndDate = watch(
	() => bingoData.value?.bingoEndDate,
	() => {
		if (bingoData.value?.bingoEndDate !== null) {
			reset(bingoData.value?.bingoEndDate ?? "");
		}
	}
);

const handleClick = () => {
	loginGuard({
		success() {
			if (isCanPlay.value) {
				open("LazyOModalBingoPlay");
				return;
			}

			navigateTo("/issues/popular-games/");
		},
		fail() {
			open("LazyOModalSignup");
		}
	});
};

const handleOpenHiwModal = () => {
	open("LazyOModalBingoHowItWorks");
};

onUnmounted(() => {
	unwatchBingoEndDate();
});
</script>

<template>
	<ACard
		class="bingo"
		width="292px"
		height="380px"
		:imgAttrs="{
			width: 292,
			height: 380,
			src: '/nuxt-img/bingo/bg-promo.png',
			alt: 'bingo promo bg',
			format: 'avif',
			loading: 'lazy'
		}"
		alt="bingo"
		@click="handleClick"
	>
		<template #topLine>
			<AText class="countdown" :modifiers="['uppercase', 'center', 'medium']" as="div">
				<template v-if="!isGuest && bingoData?.bingoEndDate">
					{{ t("bingo.promo.card.timer") }}&nbsp;{{ promotionTime }}
				</template>
				<template v-else>{{ t("bingo.promo.card.txt1") }}</template>
			</AText>
		</template>
		<template #top>
			<MPromotionHeadlineFont>
				{{ t("bingo.promo.card.title") }}
			</MPromotionHeadlineFont>

			<AText :size="24" class="btn-info" @click.stop.prevent="handleOpenHiwModal">
				<ASvg name="24/info" filled />
			</AText>

			<div class="wrapp-info flex-center">
				<AText v-if="isGuest" :size="24" class="subtitle" :modifiers="['bold', 'center']" as="div">
					{{ t("bingo.promo.card.txt") }}
				</AText>
				<MBingoProgress v-else />
			</div>
		</template>
		<template #bottom>
			<div class="image flex-center">
				<NuxtImg
					src="/nuxt-img/bingo/promo.png"
					format="avif"
					width="411"
					height="249"
					loading="lazy"
					alt="promo img"
				/>
			</div>
			<AButton variant="primary" size="xl" :modifiers="['wide']">
				<AText :size="18" :modifiers="['medium']">
					{{ t(buttonName) }}
				</AText>
			</AButton>
		</template>
	</ACard>
</template>

<style scoped lang="scss">
.btn-info {
	display: inline-flex;
	padding: 6px;
	position: absolute;
	top: 0;
	right: 14px;
	cursor: pointer;
}

.bingo {
	:deep(.progress-wrapper) {
		.progress {
			gap: 0;
			padding: 6px;
		}

		.text {
			font-size: 14px;
			font-weight: 500;

			span {
				font-weight: 700;
			}
		}
	}
}
.wrapp-info {
	flex-direction: column;
	width: 250px;
	transform: translateX(calc(50% - 125px));
	position: relative;
	height: 120px;
}
.subtitle {
	display: block;
	width: 200px;
	margin-bottom: 20px;
}
.image {
	position: absolute;
	bottom: 30px;
	width: 266px;
	left: calc(50% - 133px);
	overflow: hidden;
	padding-top: 50px;

	img {
		width: 274px;
		height: 166px;
		animation: coins 3s ease-in-out infinite;
		filter: drop-shadow(0 0 10px rgba(0, 255, 195, 0.5));
	}
}

@keyframes coins {
	50% {
		filter: drop-shadow(0 0 30px rgba(0, 255, 195, 1));
	}
}
</style>
